.backdrop {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	z-index: 1000;
	background: rgba(0, 0, 0, 0.75);
}

.errmodal {
	position: fixed;
	display: flex;
	flex-direction: column;
	top: 30vh;
	left: 30%;
	width: 40%;
	z-index: 100;
	overflow: hidden;

	&__header {
		// background: rgba(255, 255, 255, 0.5);
		background: #042c54; /* fallback for old browsers */
		// background: linear-gradient(113.49deg, #001e6c -30.3%, #181e41 58.12%);

		padding: 1rem;

		h2 {
			font-size: 24px;
			margin: 0;
			color: #fff;
		}
	}

	&__main {
		background-color: #DFF6FF;
		// background: linear-gradient(to right, #ffb2a6, #ff8aae);
		min-height: 20vh;
		padding: 1rem;

		p {
			font-size: 20px;
			color: black;
			text-align: left;
		}
		a {
			font-size: 28px;
			text-decoration: none;
		}

		input {
			height: 3rem;
			text-align: center;
			margin: 2rem auto;
			font-size: 28px;
			width: 100%;
			appearance: none;
			border-radius: 200px;
			border: none;
			&:focus {
				outline: 1px solid #fff;
			}
		}
	}

	&__footer {
		background:#042c54; /* fallback for old browsers */
		// background: linear-gradient(113.49deg, #001e6c -30.3%, #181e41 58.12%);
		padding: 1rem;	
		display: flex;
		justify-content: center;

		button {
			// width: 100%;
			background-image: linear-gradient(
				to right,
				#ec6f66 0%,
				#f3a183 51%,
				#ec6f66 100%
			);
			background-size: 200% auto;
			color: #fff;
			transition: 0.5s;

			margin: 1.5rem 0;
			// gap: 1.5rem;

			font-size: 1.3rem;
			font-weight: 500;
			border-radius: 1rem;

			padding: 1rem;

			box-shadow: 0 0 5px #eee;
			cursor: pointer;
			transition: all 0.2s ease-in-out;
			border: none;
			text-transform: uppercase;
			margin-right: 3rem;

			&:hover {
				background-position: right center;
			}
			&:active {
				transform: scale(0.95);
			}
		}
	}

	&__btn1 {
		padding: 10px 24px;
		font-size: 1rem;
		border-radius: 10px;
		cursor: pointer;
		text-align: center;
		background-color: white;
		

	
		&:hover {
			transform: translateY(-2px);

		}
	}
}

@media (min-width: 768px) {
	.err__modal {
		left: calc(50% - 20rem);
		width: 40rem;
    }
}