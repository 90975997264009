.whymatter {
	display: flex;
	flex-direction: row;
	padding: 3rem 5rem;
	justify-content: center;
	align-items: center;
	// margin: 3rem 2rem;

	@media (max-width: 600px) {
		flex-direction: column;
		padding: 3rem 1rem;
	}

	&__left {
		flex: 1;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding: 1rem;
		flex-basis: 30%;
		// background-color: red;

		&--img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}

		@media (max-width: 600px) {
			flex-basis: 100%;
			padding: 0;
		}
	}

	&__right {
		flex: 1;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;
		padding: 1rem;
		flex-basis: 50%;
		text-align: left;

		&--title {
			font-family: var(--font-family);
			font-size: 2rem;
			font-weight: 800;
			text-align: center;
			line-height: 3rem;
			margin-bottom: 1rem;
			text-align: left;
		}

		&--text {
			font-family: var(--font-family);
			color: var(--color-text);
			font-size: 1rem;
			font-weight: 400;
			font-size: 16px;
			line-height: 30px;

			margin-bottom: 2rem;
		}
	}

	@media (max-width: 600px) {
		flex-basis: 100%;
		padding: 1rem;
	}
}
