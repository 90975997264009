.gpt3__header {
	display: flex;
	flex-direction: row;
	padding: 0rem 6rem;

	@media screen and (max-width: 768px) {
		padding: 2rem 2rem;
	}
	/* align-items: center; */
}

.gpt3__header-content {
	flex: 1;
	display: flex;
	justify-content: center;
	text-align: center;
	align-items: center;
	flex-direction: column;

	margin-right: 5rem;
	// margin-top: 1rem;
}

@media screen and (min-width: 1800px) {
	.gpt3__header-content {
		justify-content: center;
		align-items: center;
		transform: translateY(-100px);
	}
}

.gpt3__header-content h1 {
	font-family: var(--font-family);
	font-weight: 800;
	font-size: 48px;
	line-height: 60px;
	letter-spacing: -0.04em;
	text-align: left;
}

.gpt3__header-content p {
	font-family: var(--font-family);
	font-weight: 400;
	font-size: 16px;
	line-height: 20px;
	color: var(--color-text);
	text-align: left;
	margin-top: 1.5rem;
}

.gpt3__header-content__input {
	width: 100%;
	margin: 2rem 0 1rem;

	display: flex;
	flex-direction: row;
	justify-content: center;
	// align-items: flex-start;
}

.gpt3__header-content__input input {
	flex: 2;
	width: 100%;
	min-height: 50px;
	font-family: var(--font-family);
	font-weight: 400;
	font-size: 20px;
	line-height: 28px;
	background: var(--color-footer);
	border: 2px solid var(--color-footer);
	padding: 0 1rem;
	outline: none;
	color: #fff;

	border-top-left-radius: 5px;
	border-bottom-left-radius: 5px;
}

.gpt3__header-content__input button {
	flex: 0.6;
	width: 100%;
	min-height: 50px;
	font-family: var(--font-family);
	font-weight: 400;
	font-size: 20px;
	line-height: 28px;
	background: #ff4820;
	border: 2px solid #ff4820;
	padding: 0 1rem;
	color: #fff;
	cursor: pointer;
	outline: none;

	border-top-right-radius: 5px;
	border-bottom-right-radius: 5px;
}

.gpt3__header-content__people {
	width: 100%;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	flex-direction: row;

	margin-top: 2rem;
}

.gpt3__header-content__people img {
	width: 181.79px;
	height: 38px;
}

.gpt3__header-content__people p {
	margin: 0 0 0 1rem;
	font-family: var(--font-family);
	font-weight: 500;
	font-size: 12px;
	line-height: 38px;
	color: #fff;
	text-align: center;
}

.gpt3__header-image {
	flex: 1;
	display: flex;
	justify-content: center;
	align-items: center;
	// margin-top: -2rem;
}

.gpt3__header-image img {
	width: 100%;
	height: 100%;

	@media screen and (min-width: 1800px) {
		width: 100%;
		height: 100%;
	}

	@media screen and (max-width: 768px) {
		width: 100%;
		height: 100%;
	}
}

.float {
	animation: float 2.5s linear infinite;
}

@keyframes float {
	0%,
	100% {
		transform: translateY(0rem);
	}

	50% {
		transform: translateY(1rem);
	}
}

@media screen and (max-width: 1050px) {
	.gpt3__header {
		flex-direction: column;
	}

	.gpt3__header-content {
		margin: 0 0 3rem;
	}
}

@media screen and (max-width: 650px) {
	.gpt3__header-content {
		margin-top: -2rem;
	}

	.gpt3__header-content h1 {
		font-size: 48px;
		line-height: 60px;
	}

	.gpt3__header-content p {
		font-size: 16px;
		line-height: 24px;
	}

	.gpt3__header-content__people {
		flex-direction: column;
	}

	.gpt3__header-content__people p {
		margin: 0;
	}

	.gpt3__header-content__input input,
	.gpt3__header-content__input button {
		font-size: 16px;
		line-height: 24px;
	}
}

@media screen and (max-width: 490px) {
	.gpt3__header-content h1 {
		font-size: 36px;
		line-height: 48px;
	}

	.gpt3__header-content p {
		font-size: 14px;
		line-height: 24px;
	}

	.gpt3__header-content__input input,
	.gpt3__header-content__input button {
		font-size: 12px;
		line-height: 16px;
	}
}
