.privacy {
	min-height: 100vh;
	padding: 3rem 6rem;
	text-align: center;
	font-family: var(--font-family);

	&__para {
		font-size: 1.3rem;
		color: #fdeff4;
		text-align: start;
		padding: 0 2rem;
		margin-bottom: 2rem;
		.unlist{
			margin-left: 3rem;
		}
		.olist{
			list-style-type: lower-alpha;
			&--i{
				list-style-type: lower-roman;
				margin-left: 3rem;
			}
			&--decimal{
				list-style-type: decimal;
			}
			&--alpha{
				list-style-type: lower-alpha;
				margin-left: 3rem;
			}
		}
	}

	&__head {
		color: #77ACF1;
		font-size: 2rem;
		padding: 0 2rem;
		margin-bottom: 2rem;
		text-transform: uppercase;
		// text-align: start;
		&--1{
			font-size: 2.5rem;
		}
	}

	&__head1 {
		font-size: 1.3rem;
	}
}