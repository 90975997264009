.whysquirrel {
	background-image: url(../../assets/whysq.webp);
	height: 105vh;
	background-size: cover;
	background-position: center;
	background-blend-mode: overlay;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	// color: #fff;
	text-align: center;
	padding: 20px;
	position: relative;

	&__title {
		display: flex;
		justify-content: center;
		align-items: center;
		font-family: var(--font-family);
		margin: 0 auto;
		font-size: 3rem;
		font-weight: 600;
		margin-top: 3rem;
		color: #fff;
		@media screen and (max-width: 760px) {
			font-size: 2rem;
		}

		@media screen and (min-width: 1800px) {
			margin-top: 7rem;
		}
	}

	&__text {
		font-family: var(--font-family);
		color: #fff;
		// color: var(--color-text);
		font-size: 1.2rem;
		line-height: 1.5;
		margin-top: 1rem;
		@media screen and (max-width: 760px) {
			font-size: 1rem;
		}
	}
	&__text-2 {
		color: var(--color-subtext);
		margin-top: 2rem;
		padding: 1rem 6rem;

		@media screen and (max-width: 760px) {
			padding: 1rem 2rem;
		}
	}
}
