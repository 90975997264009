.section__bg {
	padding: 4rem 6rem;
	// background-image: url(../../assets/featbg.jpg);
	// background-size: cover;
	// background-position: center;
	// background-blend-mode: overlay;
	@media screen and (max-width: 760px) {
		padding: 4rem 2rem;
		background-image: none;
	}
}

.gpt3__features {
	width: 100%;
	display: flex;
	justify-content: space-between;
	flex-direction: row;
	align-items: center;
}

.gpt3__features-heading {
	flex: 1;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	margin-left: 5rem;

	text-align: left;
}

.gpt3__features-heading-2 {
	text-align: left;
	transform: translateX(-4rem);

	@media screen and (max-width: 760px) {
		transform: translateX(0rem);
	}
}

.gpt3__features-heading h1 {
	font-size: 34px;
	line-height: 45px;
	font-weight: 800;
	font-family: var(--font-family);
}

.gpt3__features-heading p {
	color: var(--color-subtext);
	font-size: 16px;
	line-height: 30px;
	font-weight: 500;
	font-family: var(--font-family);

	margin-top: 2rem;
}

.gpt3__features-container {
	flex: 1.5;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	flex-direction: column;
	height: 100%;
	width: 100%;

	&--img {
		width: 80%;
		height: 80%;
	}
}

@media screen and (max-width: 990px) {
	.gpt3__features {
		flex-direction: column;
		margin-bottom: 2rem;
	}
	.gpt3__features-2 {
		flex-direction: column-reverse;
	}

	.gpt3__features-heading {
		margin: 2rem 0;
		padding: 2rem;
	}
}

@media screen and (max-width: 550px) {
	.gpt3__features-heading h1 {
		font-size: 28px;
		line-height: 38px;
	}
}
