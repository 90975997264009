.aboutus {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;

	&__home {
		// background-color: #040c18;
		background-color: #0d1727;
		background-image: url(../../assets/aboutbg.webp);
		// height: 100vh;
		background-size: cover;
		background-position: center;
		background-blend-mode: overlay;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		// color: #fff;
		text-align: center;
		padding: 20px;
		position: relative;

		@media screen and (max-width: 760px) {
			background-image: none;
			background-color: none;
		}

		&__container {
			display: flex;
			flex-direction: column;
			justify-content: center;
			// transform: translateY(-30px);
			align-items: flex-start;
			width: 100%;
			height: 100%;
			padding: 0 100px;
			text-align: left;
			padding: 0 5rem;
			@media screen and (min-width: 1800px) {
				transform: translateY(100px);
				justify-content: flex-start;
			}
			@media screen and (max-width: 760px) {
				// transform: translateY(300px);
				padding: 0 50px;
			}

			&--title {
				margin: 0 auto;
				font-size: 3rem;
				font-weight: 600;
				margin-bottom: 1rem;
				color: #fff;
				@media screen and (max-width: 760px) {
					font-size: 2rem;
				}
			}

			&--text {
				font-family: var(--font-family);
				// color: var(--color-text);
				color: #fff;
				font-size: 1rem;
				font-weight: 400;
				font-size: 16px;
				line-height: 30px;

				margin-bottom: 1rem;
				margin-left: 0;

				@media screen and (min-width: 1400px) {
					font-size: 1.2rem;
					margin-bottom: 2rem;
				}
			}
		}

		&--title {
			font-family: var(--font-family);
			font-weight: 700;
			font-size: 52px;
			line-height: 75px;
			letter-spacing: -0.04em;
			position: absolute;
			top: 82vh;
			// text-shadow: 3px 3px 3px #285fb0,
			// -2px, 1px 30px #fff;

			@media screen and (max-width: 768px) {
				font-size: 32px;
				line-height: 45px;
				top: 80vh;
				visibility: hidden;
			}

			@media screen and (min-width: 1400px) {
				font-size: 72px;
				line-height: 105px;
				top: 80vh;
			}

			// transform: translateY(480px);
		}

		&__left {
			width: 50%;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			padding: 20px;
			h2 {
				font-size: 2rem;
				font-weight: 700;
				margin-bottom: 20px;
			}
			p {
				font-size: 1.2rem;
				font-weight: 400;
				margin-bottom: 20px;
			}
		}

		&__right {
			width: 50%;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			padding: 20px;
			h2 {
				font-size: 2rem;
				font-weight: 700;
				margin-bottom: 20px;
			}
			p {
				font-size: 1.2rem;
				font-weight: 400;
				margin-bottom: 20px;
			}
		}
	}

	.aboutus__content {
		width: 50%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		.aboutus__content__title {
			font-size: 3rem;
			color: #fff;
			font-weight: 700;
			margin-bottom: 1rem;
		}
		.aboutus__content__description {
			font-size: 1.5rem;
			color: #fff;
			font-weight: 400;
			margin-bottom: 1rem;
		}
		.aboutus__content__button {
			background-color: #fff;
			color: #285fb0;
			padding: 1rem 2rem;
			border-radius: 5px;
			font-size: 1.5rem;
			font-weight: 700;
			cursor: pointer;
			transition: all 0.3s ease-in-out;
			&:hover {
				background-color: #285fb0;
				color: #fff;
			}
		}
	}
}
