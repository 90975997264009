.aboutnav__navbar {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0rem 6rem;
	margin-bottom: 2rem;

	@media screen and (max-width: 768px) {
		padding: 0.5rem 2rem;
		// margin-top: 5rem;
	}
}

.aboutnav__navbar-links {
	flex: 1;
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.aboutnav__navbar-links_logo {
	margin-right: 2rem;
	margin-bottom: 1rem;
}

.aboutnav__navbar-links_logo img {
	width: 200px;
	height: 50px;
}

.aboutnav__navbar-links_container {
	display: flex;
	flex-direction: row;
}

.aboutnav__navbar-sign {
	display: flex;
	justify-content: flex-end;
	align-items: center;
}

.aboutnav__navbar-links_container p,
.aboutnav__navbar-sign p,
.aboutnav__navbar-menu_container p {
	color: #fff;
	font-family: var(--font-family);
	font-weight: 500;
	font-size: 18px;
	line-height: 25px;
	text-transform: capitalize;

	margin: 0 1rem;
	cursor: pointer;
}

.aboutnav__navbar-sign button,
.aboutnav__navbar-menu_container button {
	padding: 0.5rem 1rem;
	color: #fff;
	background: #ff4820;
	font-family: var(--font-family);
	font-weight: 500;
	font-size: 18px;
	line-height: 25px;
	border: none;
	outline: none;
	cursor: pointer;
	border-radius: 5px;
}

.aboutnav__navbar-menu {
	margin-left: 1rem;
	z-index: 1000;
	display: none;
	position: relative;
}

.aboutnav__navbar-menu svg {
	cursor: pointer;
}

.aboutnav__navbar-menu_container {
	display: flex;
	justify-content: flex-end;
	align-items: flex-end;
	flex-direction: column;

	text-align: end;
	background: var(--color-footer);
	padding: 2rem;
	position: absolute;
	right: 0;
	top: 40px;
	margin-top: 1rem;
	min-width: 210px;
	border-radius: 5px;
	box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
}

.aboutnav__navbar-menu_container p {
	margin: 1rem 0;
}

.aboutnav__navbar-menu_container-links-sign {
	display: none;
}

@media screen and (max-width: 1050px) {
	.aboutnav__navbar-links_container {
		display: none;
	}

	.aboutnav__navbar-menu {
		display: flex;
	}
}

@media screen and (max-width: 700px) {
	.aboutnav__navbar {
		padding: 2rem 4rem;
	}
}

@media screen and (max-width: 550px) {
	.aboutnav__navbar {
		padding: 2rem;
	}

	.aboutnav__navbar-sign {
		display: none;
	}

	.aboutnav__navbar-menu_container {
		top: 20px;
	}

	.aboutnav__navbar-menu_container-links-sign {
		display: block;
	}
}
