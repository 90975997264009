.footer {
	background: var(--color-footer);
	color: #fff;
	display: flex;
	flex-direction: column;
	align-items: center;
	font-size: 1.1rem;
	font-family: var(--font-family);

	.border-line {
		display: block;
		height: 1px;
		border: 0;
		border-top: 1px solid #fff;
		margin: 1em 0;
		padding: 0;
		color: white;
		width: 60%;
	}

	&__social {
		display: flex;
		// font-size: 1.8rem;
		padding-top: 1.2rem;

        &--head{
            margin-top: .9rem;
            // font-size: 1.5rem;
            text-transform: uppercase;
            // color: #e5dcc3;
        }

		a {
			width: 3rem;
			height: 3rem;
			border-radius: 50%;
			background: rgba(0, 0, 0, 0.1);
			margin: 0.25rem 1rem;
			
			// border: 1px solid #eee;
			color: #3d56b2;

			display: flex;
			justify-content: center;
			align-items: center;

			transition: all 0.3s ease-in-out;

			&:hover {
				background-color: transparent;
			}
		}
	}

	&__pages {
		ul {
			display: flex;
			justify-content: flex-end;
			align-items: center;
			padding: 0.3rem 1rem 0 1rem;
			list-style: none;

			li {
				margin: 1rem;
				a {
					text-decoration: none;
					// text-transform: uppercase;
					color: #fff;
					&:hover {
						color: #e5dcc3;
					}
				}
			}
		}
	}

	&__copy {
		padding: 1rem;
		text-transform: uppercase;
		// color: #e5dcc3;
		border: 0;
		display: flex;
		flex-direction: column;

		&--right {
			margin: 1rem auto;
		}
	}
	img {
		height: 3rem;
		margin: 1.2rem;
		// padding: 1rem;
	}
}
