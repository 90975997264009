.swiperslides {
	display: flex;
	justify-content: center;
	align-items: center;

	@media screen and (max-width: 600px) {
		padding: 0 2rem;
	}
}

.swiperC {
	width: 340px;
	height: 420px;

	@media screen and (max-width: 600px) {
		width: 300px;
		height: 450px;
	}
}

.swiper-slideC {
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 18px;
	font-size: 22px;
	font-weight: bold;
	color: #fff;
	background: radial-gradient(
		circle at 30% -100%,
		#042c54 25%,
		rgba(4, 44, 84, 1) 85%,
		rgba(27, 120, 222, 1) 100%
	);

	.swiperslider__item {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		margin: 10px;
		padding: 1rem 2rem;
		height: 100%;
		// border-radius: 10px;
		cursor: pointer;
		transition: all 0.3s ease-in-out;
		// background: var(--color-footer);
		filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
		border-radius: 5px;
		transition: all 0.3s ease-in-out;

		@media screen and (max-width: 768px) {
			width: 100%;
		}

		.swiperslider__item__icon {
			font-size: 5rem;
			// color: #000;
			margin-bottom: 1rem;
		}

		.swiperslider__item__title {
			font-size: 2rem;
			font-weight: 600;
			margin-bottom: 1rem;
			text-align: center;
		}

		.swiperslider__item__desc {
			font-size: 1.2rem;
			font-weight: 400;
			text-align: center;
		}
	}
}
