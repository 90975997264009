.iconspace__container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin: 2rem 0;
	color: #fff;
	font-family: var(--font-family);

	@media screen and (max-width: 600px) {
		padding: 0 1rem;
	}

	&--title {
		font-size: 2.5rem;
		font-weight: 600;
		margin-bottom: 1rem;

		@media screen and (max-width: 600px) {
			padding: 0 2rem;
			font-size: 2rem;
		}
	}
}

.iconspace {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	align-content: center;
	padding: 3rem 1rem;

	@media screen and (max-width: 768px) {
		width: 100%;
		padding: 2rem;
	}

	.iconspace__item {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		width: 30%;
		min-height: 16rem;
		margin: 10px;
		padding: 1rem 2rem;
		border-radius: 10px;
		cursor: pointer;
		transition: all 0.3s ease-in-out;
		background: var(--color-footer);
		background: radial-gradient(
			circle at 30% -100%,
			#042c54 25%,
			rgba(4, 44, 84, 1) 85%,
			rgba(27, 120, 222, 1) 100%
		);

		filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
		border-radius: 5px;
		transition: all 0.3s ease-in-out;

		&:hover {
			transform: scale(1.05);
			border: 1px solid #000;
			box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
		}

		@media screen and (max-width: 768px) {
			width: 100%;
		}

		.iconspace__item__icon {
			font-size: 3rem;
			color: #000;
			margin-bottom: 1rem;
		}

		.iconspace__item__title {
			font-size: 1.5rem;
			font-weight: 600;
			margin-bottom: 1rem;
			text-align: center;
		}

		.iconspace__item__text {
			text-align: center;
			font-size: 1rem;
			font-weight: 400;
		}
	}
}
