.whoarewe {
	display: flex;
	flex-direction: row;
	padding: 3rem 5rem;
	// margin: 2rem 0;
	justify-content: center;
	align-items: center;
	min-height: 100vh;
	// margin: 3rem 2rem;

	@media (max-width: 600px) {
		flex-direction: column;
		padding: 3rem 1rem;
	}

	&__left {
		// flex: 1;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding: 1rem;
		flex-basis: 50%;

		&--text {
			font-family: var(--font-family);
			color: var(--color-text);
			font-size: 1.1rem;
			font-weight: 400;
			line-height: 30px;

			margin-bottom: 2rem;
		}
		// background-color: red;

		&--img {
			width: 70%;
			height: 70%;
			object-fit: cover;
		}

		@media (max-width: 600px) {
			flex-basis: 100%;
			// padding: 0;
		}
	}

	&__right {
		// flex: 1;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;
		padding: 1rem;
		flex-basis: 50%;
		text-align: left;

		&--title {
			font-family: var(--font-family);
			font-size: 2rem;
			font-weight: 800;
			text-align: center;
			line-height: 3rem;
			margin-bottom: 1rem;
		}

		&--text {
			font-family: var(--font-family);
			color: var(--color-text);
			font-size: 1.1rem;
			font-weight: 400;
			line-height: 30px;

			margin-bottom: 2rem;
		}

		&--text1 {
			font-family: var(--font-family);
			font-weight: 500;
			font-size: 1.2rem;
			line-height: 30px;
			color: #71e5ff;
		}
	}

	@media (max-width: 600px) {
		flex-basis: 100%;
		padding: 1rem;
	}
}

.wehelp {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin: 2rem 0;
	color: #fff;
	font-family: var(--font-family);
	// background-image: url(../../assets/handbg.jpg);
	// background-size: cover;
	// background-position: center;
	// background-blend-mode: overlay;
	min-height: 100vh;

	@media screen and (max-width: 600px) {
		background-image: none;
	}

	&--title {
		font-size: 2.5rem;
		font-weight: 600;
		margin-bottom: 2rem;

		@media screen and (max-width: 600px) {
			padding: 0 2rem;
			font-size: 2rem;
		}
	}
}

.what_title {
	font-family: var(--font-family);
	font-size: 2rem;
	font-weight: 800;
	text-align: center;
	line-height: 3rem;
	// margin-bottom: 1rem;
	background: var(--gradient-text);
	background-clip: text;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}
